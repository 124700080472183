<template>
  <v-card
    v-if="file"
    width="270"
    outlined
    hover
  >
    <v-card-text class="d-flex gap-7 align-center">
      <v-icon :color="$fileIcon(file.type, true)" left>{{ $fileIcon(file.type) }}</v-icon> 
      <div class="d-inline-block text-truncate" style="max-width: 150px;">
        <span>{{ file.file | fileName }}</span>
        <span class="caption info--text"><br> {{ file.size | fileSize }} ({{ file.type }})</span>
      </div>
      <div class="d-flex gap-3 align-center ml-auto">
        <v-btn
          v-if="file.type.includes('image')"
          :loading="gettingPreview"
          @click="showPreview()"
          depressed
          x-small
          fab
        >
          <v-icon small>mdi-eye</v-icon>
        </v-btn>
        
        <v-btn
          @click.prevent="$downloadFile('requests', file.file)"
          depressed
          x-small
          fab
        >
          <v-icon small>mdi-download</v-icon>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import firebase from 'firebase'

export default {
  props: {
    file: Object
  },

  data() {
    return {
      gettingPreview: false,
    }
  },

  methods: {
    showPreview() {
      let storage = firebase.storage()
      this.gettingPreview = true
      
      storage.ref(`requests/${this.file.file}`)
      .getDownloadURL()
      .then((url) => {
        let img = new Image
        img.src = url

        img.onload = () => {
          this.$store.commit('showImagePreview', url)
          this.gettingPreview = false
        }
      })
      .catch(error => {
        this.$store.dispatch('showError', error.message)
        this.gettingPreview = false
      })
    }
  },
}

</script>