<template>
  <div v-if="request && user" class="d-inline">
    <v-menu
      :disabled="!$store.getters['request/canChangeStatus'](request)"
      offset-y
    >
      <template #activator="{ on }">
        <v-chip
          :color="`${getColor(request.status)} lighten-5`"
          @click.prevent
          v-on="on"
          small
          label
        >
          <v-icon :color="getColor(request.status)" left x-small>
            mdi-circle
          </v-icon>
          <span :class="`${getColor(request.status)}--text`">
            {{
              user && user.role === "client" && request.status == "escalated"
                ? "in_progress"
                : request.status | startCase
            }}
          </span>
        </v-chip>
      </template>
      <v-list dense>
        <v-list-item
          v-for="(status, i) in statuses"
          @click="selected(status.value)"
          :key="i"
        >
          <v-list-item-title>{{
            status.text || status.value | startCase
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import firebase from "firebase";
import { mapState } from "vuex";
import { startCase } from "lodash";

export default {
  props: {
    request: Object,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      statuses: [
        { value: "in_progress" },
        { value: "checking_quality" },
        { value: "reviewing_with_client" },
        { value: "pending_review", text: "Completed" },
        { value: "escalated" },
        { value: "closed" },
      ],
    };
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    selected(status) {
      Promise.resolve(
        this.$store.dispatch("request/updateRequestField", {
          request: this.request,
          field: "status",
          value: status,
        })
      ).then(() => {
        if (status == "escalated") {
          this.$store.dispatch("request/updateRequestField", {
            request: this.request,
            field: "priority",
            value: "high",
            silent: true,
          });
        }

        let user = firebase.auth().currentUser;

        this.request.ref
          .collection("messages")
          .add({
            created: firebase.firestore.Timestamp.now(),
            message: `Status changed to ${startCase(status)}`,
            type: "status_change",
            sender: user.uid,
            note: false,
            html: false,
          })
          .catch((error) => {
            console.log(error.message);
          });

        if (status == "pending_review") {
          let emailData = {
            to: this.$store.getters["users/user"](this.request.user).email,
            subject: "Request Completed",
            message: `
                Hi ${
                  this.$store.getters["users/user"](this.request.user).fullName
                },<br/>
                <br/>
                Congratulations! Your request has been completed.<br/>
                Please leave us your feedback <a href="${window.origin}${
              this.$router.resolve({
                name: "Request",
                params: { id: this.request.id },
              }).href
            }">here</a>.<br/>
                <br/>
                All The Best,<br/>
                Lightning Sites Team
              `,
          };

          this.$store.dispatch("email/sendMail", emailData);
        }
      });
    },

    getColor(status) {
      if (status == "pending_review") return "amber";
      else if (status == "completed") return "light-green";
      else if (status == "paused") return "grey";
      else if (status == "closed") return "grey-darken-1";
      else if (status == "escalated")
        return this.user && this.user.role === "client" ? "blue" : "error";
      else if (["reviewing_with_client", "checking_quality"].includes(status))
        return "purple";
      else return "blue";
    },
  },
};
</script>